<template>
    <div class="col-lg-12 text-2">
        <h4 class="text-center">Refund Policy</h4>

        <p>Last updated February 21, 2024</p>

        <p class="pt-2">Thank you for using our app. This Refund Policy explains the terms and conditions under which
            refunds are
            processed for purchases made through our app.</p>
        <ol class="pl-5">
            <li class="font-weight-500">
                One-Time Payments:
            </li>
            <ol class="pl-4 pt-1" style="list-style-type:lower-alpha ">
                <li>
                    Our app/website may offer one-time payments for access to premium features or content for a specific
                    period,
                    such as monthly, 3-months, half yearly or annually.
                </li>
                <li>By making a one-time payment, you acknowledge and agree that you are purchasing access to the
                    premium
                    features or content for the specified period.
                </li>
            </ol>
            <li class="font-weight-500 pt-2">No Refunds:</li>
            <ol class="pl-4 pt-1" style="list-style-type:lower-alpha">

                <li> All payments made for access to premium features or content are non-refundable.</li>
                <li> Once a payment is made and access to premium features or content is provided, no refunds will be
                    provided,
                    regardless of whether the premium features or content are used or not.
                </li>
            </ol>
            <li class="font-weight-500 pt-2">Repurchase Required:</li>
            <ol class="pl-4 pt-1" style="list-style-type:lower-alpha">
                <li>
                    Upon expiration of the access period (monthly, 3-months, half yearly or annually), you will need to
                    repurchase the content to continue accessing it.
                </li>
                <li>There is no automatic renewal or subscription model associated with the purchase of content. Each
                    purchase
                    provides access for a specific period, after which a new purchase is required to regain access.
                </li>
            </ol>

            <li class="font-weight-500 pt-2">Payment Disputes:</li>
            <ol class="pl-4 pt-1" style="list-style-type:lower-alpha">
                <li>
                    If you believe that a payment has been made in error or without your authorization, please contact
                    our
                    customer support team immediately to resolve the issue.
                </li>
                <li>We reserve the right to investigate and take appropriate action, including but not limited to
                    suspending
                    or
                    terminating access to the premium features or content, in cases of suspected fraudulent or
                    unauthorized
                    payments.
                </li>
            </ol>
            <li class="font-weight-500 pt-2">Changes to Pricing or Features:</li>
            <ol class="pl-4 pt-1" style="list-style-type:lower-alpha">
                <li>
                    We reserve the right to change the pricing of premium features or content offered through the app at
                    any
                    time without prior notice.
                </li>
                <li> Changes to pricing or features will not affect existing subscriptions or payments made prior to the
                    change.
                </li>
            </ol>
            <li class="font-weight-500 pt-2">Contact Us:</li>
            If you have any questions or concerns about our refund policy, please contact our customer support team
            at
            foveatechvet@gmail.com

            <li class="font-weight-500 pt-2"> Agreement to Refund Policy:</li>
            By making a payment for access to premium features or content through our app, you acknowledge and agree
            to
            abide by the terms and conditions of this refund policy.
        </ol>
    </div>
</template>

<script>
export default {
    name: 'RefundPolicy'
};
</script>

<style scoped>

</style>
